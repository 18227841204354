import { SpeakerphoneIcon, XIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { useContext, useState } from "react";
import { PreferencesContext } from "../../../contexts/PreferencesContext";

interface BannerProps {
  text: string;
  buttonLinkText: string;
}

export default function Banner({ text, buttonLinkText }: BannerProps) {
  const [hide, setHide] = useState(false);
  const {hideLandingBanner, showLandingBanner} = useContext(PreferencesContext)

  return (
    <>
      {showLandingBanner ? (
        <div className="flex justify-center">
          <div className="absolute w-1/2 bg-gray-100 rounded-lg shadow-lg lg:mx-64">
            <div className="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="flex flex-wrap items-center justify-between">
                <div className="flex items-center flex-1 w-0">
                  <p className="flex-wrap ml-3 font-medium">
                    <span>{text}</span>
                  </p>
                </div>
                <Link href="/global/getting-started" passHref>
                  <button className="flex-shrink-0 order-3 w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto">
                    <div className="flex items-center justify-center px-4 py-2 font-medium text-blue-500 bg-white border border-transparent rounded-md shadow-sm">
                      Learn more
                    </div>
                  </button>
                </Link>
                <div className="flex-shrink-0 order-2 sm:order-3 sm:ml-3">
                  <button
                    type="button"
                    className="flex p-2 -mr-1 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                    onClick={() => hideLandingBanner(!showLandingBanner)}
                  >
                    <span className="sr-only">Dismiss</span>
                    <XIcon
                      className="w-6 h-6 text-gray-500"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
