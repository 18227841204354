import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";

export default function PublicBetaModal() {
  const { push } = useLobbyRouter();

  return (
    <>
      <div className="flex items-center justify-between w-full max-w-4xl px-4 py-8 overflow-hidden text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
        <div className="flex flex-col">
          <p className="text-lg font-bold text-black">Ready to get started?</p>
          <p className="text-lg font-bold text-blue-500">
            Add a wiki for your community
          </p>
        </div>
        <button
          type="button"
          className="inline-flex justify-center px-2 py-1 text-sm text-white bg-blue-600 border border-transparent rounded-md hover:opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none"
          onClick={() => push("/create-a-wiki")}
        >
          Create a wiki
        </button>
      </div>
    </>
  );
}
