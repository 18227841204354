import Link from "next/link";
import React from "react";
import { useWindowSize } from "../../../lib/hooks/useWindowSize";
import { trimEllip } from "../../../lib/util/util";

const promotedDAOs = [
  {
    name: "Shapeshift",
    url: "https://beta.lobby.so/shapeshift",
    description:
      "Explore the Defi Universe with ShapeShift. A free open source platform to trade, track, buy, and earn. Community-owned. Private. Non-custodial. Multi-chain.",
    image: "https://static.coincap.io/assets/icons/256/fox.png",
  },
  {
    name: "Twali",
    url: "https://beta.lobby.so/twali",
    description:
      "Twali was founded with the goal of creating a consultancy that is owned by it's workers",
    image: "https://cdn.lobby.so/images/communities/twali.png",
  },
  {
    name: "Lobby",
    url: "https://beta.lobby.so/lobby",
    description: "Knowledge management for Web3 organizations.",
    image: "https://cdn.lobby.so/images/logos/lobby.png",
  },
];

export default function PromotedCommunities() {
  return (
    <div className="flex flex-col max-w-4xl px-8 py-8 bg-gray-100 rounded-md">
      <div className="pb-8 font-bold">Popular</div>
      <div className="grid grid-flow-row-dense overflow-y-scroll lg:grid-flow-col lg:space-x-4">
        {promotedDAOs.map((dao, index) => {
          return (
            <Link href={dao.url} passHref key={dao.name}>
              <button>
                <CommunityCard
                  name={dao.name}
                  description={dao.description}
                  imageUrl={dao.image}
                />
              </button>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

interface CommunityCardProps {
  name: string;
  description: string;
  imageUrl: string;
}
const CommunityCard = ({ name, description, imageUrl }: CommunityCardProps) => {
  const { width } = useWindowSize();

  return (
    <div className="grid grid-rows-2 py-4 space-y-4 bg-gray-200 rounded-md place-items-center px-14">
      <div className="grid w-24 h-24 rounded-full place-items-center bg-red-50">
        <img src={imageUrl} className="rounded-full" alt="profile" />
      </div>
      <div className="grid place-items-center">
        <div className="flex flex-wrap text-lg font-bold">{name}</div>
        <div className="flex h-24 overflow-y-hidden">
          {width && width < 1025 ? description : trimEllip(description, 60)}
        </div>
      </div>
    </div>
  );
};
