import { useLazyQuery } from "@apollo/client";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { FormEvent, useEffect, useState } from "react";
import { Button, SearchBar } from "../components/atoms";
import Banner from "../components/atoms/Banner/Banner";
import { BaseLayout } from "../components/layout";
import PromotedCommunities from "../components/molecules/PromotedCommunities/PromotedCommunities";
import PublicBetaModal from "../components/organisms/PublicBetaModal/PublicBetaModal";
import { QuerySearchArgs, SearchResponseObject } from "../generated/graphql";
import { SearchQuery } from "../lib/queries/Search";

const Home = () => {
  const [value, setValue] = useState("");
  const { push } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [lobbySearchLoading, setLobbySearchLoading] = useState(false);

  const newPath = "/global/search";

  const submitForm = (e: FormEvent) => {
    e.preventDefault();
    submit();
  };

  const setSearchValue = (val: string) => {
    setValue(val);
  };

  const submit = () => {
    setLobbySearchLoading(true);
    push({ pathname: newPath, query: { query: value, origin: "Lobby home" } });
  };

  const [
    imFeelingLuckySearch,
    { error: searchError, loading: searchLoading, data: searchData },
  ] = useLazyQuery<{ search: SearchResponseObject }, QuerySearchArgs>(
    SearchQuery,
    {
      variables: {
        communitySlug: "global",
        query: value as string,
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (!searchError) return;
    enqueueSnackbar("Error occured while searching.", { variant: "error" });
  }, [searchError, enqueueSnackbar]);

  useEffect(() => {
    if (!searchData) return;

    const imFeelingLuckyUrl: string = searchData?.search?.results[0]?.uri;

    const imFeelingLuckyValue: string = searchData?.search?.results[0]?.title;

    if (imFeelingLuckyValue) {
      push({
        pathname: imFeelingLuckyUrl,
      });
    } else {
      push({ pathname: newPath, query: { query: imFeelingLuckyValue } });
    }
  }, [searchData, push]);

  return (
    <BaseLayout>
      <div className="grid space-y-16 grid-row-4 place-items-center">
        <div className="flex items-center justify-center w-full py-8 ">
          <Banner
            text={"👋 Welcome! Discover, join and contribute to DAOs."}
            buttonLinkText={"Learn More"}
          />
        </div>
        <div className="flex flex-col items-center justify-center pt-16 ">
          <div className="relative w-80 h-52">
            <Image
              layout="fill"
              src="/assets/dashboard/lobby-gradient.svg"
              alt="Lobby"
            />
          </div>
          <div className="grid grid-flow-col space-x-4">
            <div className="grid max-w-lg grid-flow-col lg:w-screen pb-7">
              <form onSubmit={(e) => submitForm(e)}>
                <SearchBar
                  setValue={setSearchValue}
                  className="flex items-center flex-grow w-full p-2 text-sm border-2 border-gray-200 border-solid rounded-xl focus:outline-none focus:border-blue-500"
                />
              </form>
            </div>
            <div>
              <Button
                text="Lobby Search"
                onClick={() => submit()}
                loading={lobbySearchLoading}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-full py-8 pt-48">
          <PublicBetaModal />
        </div>
        <div className="grid justify-center place-items-center">
          <PromotedCommunities />
        </div>
      </div>
    </BaseLayout>
  );
};

export default Home;
